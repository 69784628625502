import Vue from 'vue'
import Vuex from 'vuex'

//import modules from './modules'
import authorization from "./modules/authorization";
import notification from "./modules/notification";
import layout from "./modules/layout";
import notificator from "./modules/notificator";
import preloader from "./modules/preloader";
import legal_name from "./modules/legal_name";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    authorization,
    notification,
    layout,
    notificator,
    preloader,
    legal_name
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  //strict: process.env.NODE_ENV !== 'production',
})

export default store

