const legal_name = {
    state: {
      legal_name: '',
      request: 0
    },
    mutations: {
      setLegalName(state, legal_name) {
        state.legal_name = legal_name;
        state.request = 1;
      }
    },
    actions: {
      setLegalName({ commit }, legal_name) {
        commit("setLegalName", legal_name);
      }
    }
  };
  
  export default legal_name;
  