import store from '@/state/store'

export default [
    {
        path: '/',
        name: 'home',
        // meta: {
        //     authRequired: true,
        // },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        // meta: {
        //     beforeResolve(routeTo, routeFrom, next) {
        //         // If the user is already logged in
        //         if (store.getters['auth/loggedIn']) {
        //             // Redirect to the home page instead
        //             next({ name: 'home' })
        //         } else {
        //             // Continue to the login page
        //             next()
        //         }
        //     },
        // },
    },
    {
        path: '/management/rbac',
        name: 'rbac',
        meta: { authRequired: true },
        component: () => import('../views/pages/management/rbac')
    },
    {
        path: '/management/users',
        name: 'users',
        component: () => import('../views/pages/management/users')
    },
    {
        path: '/management/franchise',
        name: 'franchise',
        component: () => import('../views/pages/management/franchise')
    },
    {
        path: '/management/printers',
        name: 'printers',
        component: () => import('../views/pages/management/printers')
    },
    {
        path: '/management/printers-sis',
        name: 'printers-sis',
        component: () => import('../views/pages/management/printers_sis')
    },
    {
        path: '/management/printers-map',
        name: 'printers-map',
        component: () => import('../views/pages/management/printers_map')
    },
    {
        path: '/management/orders',
        name: 'orders',
        component: () => import('../views/pages/management/orders')
    },
    {
        path: '/management/clients',
        name: 'clients',
        component: () => import('../views/pages/management/clients')
    },
    {
        path: '/management/banners',
        name: 'clients',
        component: () => import('../views/pages/management/banners')
    },
    {
        path: '/admin/servers',
        name: 'servers',
        component: () => import('../views/pages/admin/servers')
    },
    {
        path: '/admin/configs',
        name: 'servers',
        component: () => import('../views/pages/admin/configs')
    },
    {
        path: '/management/localization',
        name: 'localization',
        component: () => import('../views/pages/management/localization')
    },
    {
        path: '/management/metro-stations',
        name: 'metro-stations',
        component: () => import('../views/pages/management/metro_stations')
    },
    {
        path: '/management/promo-codes',
        name: 'promo-codes',
        component: () => import('../views/pages/management/promo_codes')
    },
    {
        path: '/management/push-notifications',
        name: 'push-notifications',
        component: () => import('../views/pages/management/push_notifications')
    },
    {
        path: '/services/points',
        name: 'points',
        component: () => import('../views/pages/services/points')
    },
    {
        path: '/services/templates',
        name: 'templates',
        component: () => import('../views/pages/services/templates')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },

    // ===============================================================================
]
